.skills-container {
    background-color: transparent;
    padding: 2rem;
    font-family: Arial, sans-serif;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .skills-container h1 {
    text-align: center;
    color: white;
    font-size: 3rem;
    margin-bottom: 2rem;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    justify-content: center;
  }
  
  .skill-item {
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    font-size: 1.2rem;
    color:#0077b5;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
  }

  
  .skill-item:hover {
    transform: translateY(-5px);
  }
  
  .skills-grid::after {
    content: "";
    grid-column: span 3;
  }
  
  .skills-grid > *:nth-last-child(-n+3):first-child,
  .skills-grid > *:nth-last-child(-n+3):first-child ~ * {
    grid-column: auto / span 1;
  }
  
  .skills-grid > *:nth-last-child(-n+2):first-child,
  .skills-grid > *:nth-last-child(-n+2):first-child ~ * {
    grid-column: 2 / span 1;
  }
  
  @media (max-width: 768px) {
    .skills-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .skills-grid::after {
      grid-column: span 2;
    }
  
    .skills-grid > *:nth-last-child(-n+2):first-child,
    .skills-grid > *:nth-last-child(-n+2):first-child ~ * {
      grid-column: auto / span 1;
    }
  
    .skills-container h1 {
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .skills-grid {
      grid-template-columns: 1fr;
    }
  
    .skills-grid::after {
      display: none;
    }
  }