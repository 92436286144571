.aboutmepage{
    text-align:left;
    margin-left: 150px;
}

.aboutmepara{
    width:500px;
    margin-left:10px;
    color: rgb(237, 237, 237);
}

.aboutmeheading{
    background-image: linear-gradient(to right, rgb(48, 33, 205), #0077b5);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.aboutmeinnerheading{
    margin-left: 5px;
    background-image: linear-gradient(to right, rgb(48, 33, 205), #0077b5);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.aboutcontainer{
    display:grid;
    grid-template-columns: 1fr 1fr;
}

.vsnimage{
    width:47%;
    border-radius:12px;
    margin-top:15px;
}




