.currentprojectspage{
    margin-left: 150px;
    text-align:left;
    margin-bottom:200px;
}

.currentheading{
    margin-left:5px;
    background-image: linear-gradient(to right, rgb(48, 33, 205), #0077b5);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.currentdescription{
    margin-left:10px;
    color:white;
}
.heading{
    background-image: linear-gradient(to right, rgb(48, 33, 205), #0077b5);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-bottom:35px;
}

.iframe{
    width:90%;
    min-height:35rem;
    border-radius:12px;
}