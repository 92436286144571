@tailwind components;
@tailwind utilities;

.logo{
    height:110px;
}

.ok {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 125px;
    width: 100%;
    padding: 0 2rem;
    /*background-color: rgb(220, 151, 151);*/
    background-color: #949cc9;
  }
  .logo-container {
    flex: 0 0 auto;
  }
  .links-container {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .nav-link {
    color: white;
    text-decoration: none;
  }
   .nav-link:hover {
    color: #b83535; 
}
  @media (max-width: 768px) {
    .links-container {
      display: none;
    }
  }

  hr{
    width:90%;
  }

  .hrcolor{
    background-color:#0077b5
  }